import { useRef, useState } from "react";
import classes from "./Checkout.module.css";

const isEmpty = (value) => value.trim() === "";
const isFiveNums = (value) => value.trim().length !== 5;

const Checkout = (props) => {
	const [formValidity, setFormValidity] = useState({
		name: true,
		street: true,
		postal: true,
		city: true,
	});

	const nameInputRef = useRef();
	const streetInputRef = useRef();
	const postalInputRef = useRef();
	const cityInputRef = useRef();

	const confirmHandler = (event) => {
		event.preventDefault();

        const enteredName = nameInputRef.current.value;
        const enteredStreet = streetInputRef.current.value;
        const enteredPostal = postalInputRef.current.value;
        const enteredCity = cityInputRef.current.value;

		const enteredNameIsValid = !isEmpty(enteredName);
		const enteredStreetIsValid = !isEmpty(enteredStreet);
		const enteredPostalIsValid = !isFiveNums(enteredPostal);
		const enteredCityIsValid = !isEmpty(enteredCity);

		setFormValidity({
			name: enteredNameIsValid,
			street: enteredStreetIsValid,
			postal: enteredPostalIsValid,
			city: enteredCityIsValid,
		});

		const formIsValid =
			enteredNameIsValid &&
			enteredCityIsValid &&
			enteredStreetIsValid &&
			enteredPostalIsValid;

		if (!formIsValid) {
			return;
		}

        props.onCheckout({
					name: enteredName,
					street: enteredStreet,
					postal: enteredPostal,
					city: enteredCity,
				});
	};


	return (
		<form onSubmit={confirmHandler} className={classes.form}>
			<div
				className={`${classes.control} ${
					formValidity.name ? "" : classes.invalid
				}`}
			>
				<label htmlFor="name">Your Name</label>
				<input type="text" id="name" ref={nameInputRef} />
				{!formValidity.name && <p>Please enter a valid name!</p>}
			</div>
			<div
				className={`${classes.control} ${
					formValidity.street ? "" : classes.invalid
				}`}
			>
				<label htmlFor="street">Street</label>
				<input type="text" id="street" ref={streetInputRef} />
				{!formValidity.street && <p>Please enter a valid stree!</p>}
			</div>
			<div
				className={`${classes.control} ${
					formValidity.postal ? "" : classes.invalid
				}`}
			>
				<label htmlFor="postal">Zip Code</label>
				<input type="text" id="postal" ref={postalInputRef} />
				{!formValidity.postal && (
					<p>Please enter a valid postal 5 digits long!</p>
				)}
			</div>
			<div
				className={`${classes.control} ${
					formValidity.city ? "" : classes.invalid
				}`}
			>
				<label htmlFor="city">City</label>
				<input type="text" id="city" ref={cityInputRef} />
				{!formValidity.city && <p>Please enter a valid city!</p>}
			</div>
			<div className={classes.actions}>
				<button type="button" onClick={props.onCancel}>
					Cancel
				</button>
				<button className={classes.submit}>Confrim</button>
			</div>
		</form>
	);
};

export default Checkout;
