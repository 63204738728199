import { useContext, useState, Fragment } from "react";

import Modal from "../UI/Modal";
import CartItem from "./CartItem";
import Checkout from "./Checkout";
import classes from "./Cart.module.css";
import CartContext from "../../store/cart-context";

const Cart = (props) => {
	const [showCheckout, setShowCheckout] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isDone, setIsDone] = useState(false);

	const cartCtx = useContext(CartContext);

	const totalAmount = `$${cartCtx.totalAmount.toFixed(2)}`;
	const hasItems = cartCtx.items.length > 0;

	const cartItemRemoveHandler = (id) => {
		cartCtx.removeItem(id);
	};

	const cartItemAddHandler = (item) => {
		cartCtx.addItem(item);
	};

	const orderClickHandler = () => {
		setShowCheckout(true);
	};

	const checkoutHandler = async (userData) => {
		setIsSubmitted(true);
		await fetch(
			"https://foodfetch-582bf-default-rtdb.firebaseio.com/orders.json",
			{
				method: "POST",
				body: JSON.stringify({
					user: userData,
					order: cartCtx.items,
				}),
			}
		);
		setIsSubmitted(false);
		setIsDone(true);
    cartCtx.clearCart()
	};

	const cartItems = (
		<ul className={classes["cart-items"]}>
			{cartCtx.items.map((item) => (
				<CartItem
					key={item.id}
					name={item.name}
					amount={item.amount}
					price={item.price}
					onRemove={cartItemRemoveHandler.bind(null, item.id)}
					onAdd={cartItemAddHandler.bind(null, item)}
				/>
			))}
		</ul>
	);

	const modalActions = (
		<div className={classes.actions}>
			<button className={classes["button--alt"]} onClick={props.onClose}>
				Close
			</button>
			{hasItems && (
				<button
					type="button"
					className={classes.button}
					onClick={orderClickHandler}
				>
					Order
				</button>
			)}
		</div>
	);

	const cartModalContent = (
		<Fragment>
			{cartItems}
			<div className={classes.total}>
				<span>Total Amount</span>
				<span>{totalAmount}</span>
			</div>
			{showCheckout && (
				<Checkout onCancel={props.onClose} onCheckout={checkoutHandler} />
			)}
			{!showCheckout && modalActions}
		</Fragment>
	);

	const submittingModal = <p>Sending your order...</p>;

	const doneModal = <p>Sucessfully submitted your order!</p>;

	return (
		<Modal onClose={props.onClose}>
			{!isSubmitted && !isDone && cartModalContent}
			{isSubmitted && submittingModal}
			{!isSubmitted && isDone && doneModal}
		</Modal>
	);
};

export default Cart;
